<template>
  <div id="BlogView">
    <HeaderPage title="Blog" />
    <div class="container-fluid xs md xxl section-page">
      <div class="row m-n3">
        <div id="blogContent" class="col-12 col-lg-auto flex-grow-1 p-3">
          <router-view />
        </div>
        <div id="blogSideBar" class="col-12 col-lg-auto d-flex flex-column flex-grow-1 p-3">
          <SideBar>
            <BlogSideBar></BlogSideBar>
          </SideBar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import HeaderPage from '@/components/layout/HeaderPage'
  import BlogSideBar from '@/components/view/blog/BlogSideBar'
  import SideBar from '@/components/layout/SideBar'

  export default {

    name: 'InmueblesView',

    data () {
      return {
        option: {
          topSpacing: 120,
          bottomSpacing: 30,
          resizeSensor: true,
          stickyClass: "is-affixed",
          minWidth: 992
        }
      }
    },
    components:{
      HeaderPage,
      BlogSideBar,
      SideBar
    }
  }
</script>

<style lang="css">
  @media(min-width: 992px){
    #blogSideBar{
      max-width: 320px;
    }
    #blogContent{
      width: 50%;
    }
  }

  #App .widget.newsletter [type="submit"] {
    background: #fff;
    color: var(--tm-color);
    font-weight: 500;
    border: none;
  }
</style>