<template>
	<div class="widget-container">
		<div v-if="false" class="widget mb-4">
			<h3 class="title-widget">
				Fecha
			</h3>
			<div class="content-widget">
				<b-calendar block v-model="value" :min="min" :max="max" locale="en"></b-calendar>
			</div>
		</div>
		<div class="widget newsletter">
			<h3 class="title-widget">
				Suscribete
			</h3>
			<div class="content-widget">
				<!-- <p>
					Lorem ipsum dolor sit amet, consectetur adipisicing elit.
				</p> -->
				<Form v-bind="form" />
			</div>
		</div>
	</div>
</template>

<script>
	import Form from '@/components/view/form/Form'
	export default {

		name: 'BlogSideBar',

		data () {
			const now = new Date()
			const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
			// 15th two months prior
			const minDate = new Date(today)
			minDate.setMonth(minDate.getMonth() - 2)
			minDate.setDate(15)
			// 15th in two months
			const maxDate = new Date(today)
			maxDate.setMonth(maxDate.getMonth() + 2)
			maxDate.setDate(15)

		return {
			value: '',
			min: minDate,
			max: maxDate,
			form: {
				api: 'api/clientes_subscripto',
				btn: {text: 'Suscribirse', class:'d-block'},
				inputs: {
					name: {
						value: null,
						component: 'b-form-input',
						attr: {
							label: {
								class: 'col-12',
								label: 'Nombre completo:',
								labelFor:'"name',
							},
							input: {
								type: 'text',	
								id: 'name',
								required: true,
							}
						}
					},
					email: {
						value: null,
						component: 'b-form-input',
						attr: {
							label: {
								class: "col-12",
								label: "Correo electrónico:",
								labelFor: "email",
							},
							input: {
								type: 'email',	
								id: 'email',
								required: true,
							}
						}
					},
					cell_phone: {
						value: null,
						component: 'b-form-input',
						attr: {
							label: {
								class: "col-12",
								label: "Celular:",
								labelFor: "cell_phone",
							},
							input: {
								type: 'text',	
								id: 'cell_phone',
								required: true,
							}
						}
					},
				},
				modal: {
					icon: 'success',
					title: '¡Felicidades!',
					text: 'Te has suscrito a nuestro boletín exitosamente',
					showCloseButton: true,
					confirmButtonText: 'Cerrar',
				}
			},
		}
	},
	components:{
		Form
	}
}
</script>

<style lang="css">

</style>