<template>
	<div id="InmueblesView">
		<router-view />
	</div>
</template>

<script>
export default {

  name: 'InmueblesView',

  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped>
</style>