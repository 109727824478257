<template>
    <div class="metaDataInmueble">
        <div class="preciosInmueble">
            <div class="valor venta" v-if="detail.selling_price">
                <span class="label">Venta:</span>
                <b>{{ detail.selling_price | precio }}</b>
            </div>
            <div class="valor alquiler" v-if="detail.rental_price">
                <span class="label">Alquiler:</span>
                <b>{{ detail.rental_price | precio }}</b>
            </div>
        </div>
        <div class="metaInmueble">
            <div class="meta tipo" v-show="detail.tipo_inmueble">
                <span class="label">Tipo de inmueble:</span>
                <span class="value">{{ detail.tipo_inmueble.tipo }}</span>
            </div>
            <div class="meta tipo" v-show="detail.tipo_negocio">
                <span class="label">Tipo de negocio:</span>
                <span class="value"><b>{{ detail.tipo_negocio.tipo }}</b></span>
            </div>
            <!-- <div class="meta direccion">
                <span class="label">Direccion:</span>
                <span class="value">{{ detail.direccion }}</span>
            </div> -->
            <div class="meta tipo" v-if="detail.ciudad_id && detail.estado_id">
                <span class="label">Ubicación:</span>
                <span class="value">{{ detail.ciudad_id.name }}, {{ detail.estado_id.name }}</span>
            </div>
            <div class="anoConstrucion">
                Año de construccion: {{ detail.ano_construcion }}
            </div>
        </div>
        <div class="agenteInmueble d-flex flex-column align-items-center">
            <figure v-if="detail.created_by.userdata.foto_persona" class="fotoAgenteInmueble">
                <img class="img-fluid mx-auto d-block" :src="detail.created_by.userdata.foto_persona" alt="">
            </figure>
            <div class="nombreAgenteInmueble">
                {{ detail.created_by.userdata.primer_nombre }}
                <span class="cargo">Agente inmobiliario</span>
            </div>
        </div>
</div>
</template>

<script>
export default {
    name: 'MetaDataInmueble',
    props: {
        detail: {
            type: Object,
            required: true
        }
    },
    data() {
        return {

        }
    },
}
</script>

<style lang="css">
.metaDataInmueble .valor {
    font-size: 28px;
    line-height: 1;
}

.metaDataInmueble .preciosInmueble .label {
    font-size: 16px;
    display: block;
    margin-bottom: 6px;
}

.metaDataInmueble .valor+.valor {
    margin-top: 15px;
}

.metaDataInmueble .valor b {
    color: var(--tm-color);
}

.metaDataInmueble .metaInmueble {
    font-size: 14px;
    margin-top: 20px;
}

.metaDataInmueble .metaInmueble .meta {
    display: flex;
}

.metaDataInmueble .metaInmueble .meta.tipo+.meta.tipo {
    margin-top: 4px;
}

.metaDataInmueble .metaInmueble .meta .value {
    margin-left: auto;
    padding-left: 8px;
}

.metaDataInmueble .metaInmueble .meta.direccion {
    border-top: 1px solid #ebe9f1;
    padding-top: 5px;
    margin-top: 5px;
}

.metaDataInmueble .anoConstrucion {
    margin: 20px 0;
    padding: 10px 35px;
    background: #e4e4e4;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.sideBarInmueble .metaDataInmueble .anoConstrucion {
    margin: 20px -35px;
}

.fotoAgenteInmueble {
    max-width: 180px;
    width: 100%;
    margin-bottom: 10px;
}

.nombreAgenteInmueble {
    text-transform: capitalize;
    font-size: 24px;
    font-weight: 400;
    text-align: center;
    line-height: 1.4;
}

.nombreAgenteInmueble .cargo {
    display: block;
    line-height: 1;
    font-size: 0.65em;
    text-transform: uppercase;
    font-weight: 700;
    color: var(--tm-cop);
}

.metaDataInmueble+* {
    border-top: 1px solid #e4e4e4;
    margin-top: 1rem;
    padding-top: 1rem;
}</style>
