<template>
	<div class="blogArchive">
		<Pagination class="m-n3" :data="blogs.grid" :perPage="5" blog />
	</div>
</template>

<script>
	import Pagination from '@/components/layout/Pagination'
	import mixinBlogs from '@/libs/mixins/mixinBlogs'
	export default {

		name: 'BlogArchive',
		mixins: [mixinBlogs],
		data () {
			return {

			}
		},
		components: {
			Pagination,
		}
}
</script>
