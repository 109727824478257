<template>
	<div class="headerPage">
		<div class="container-fluid xxl position-relative z-index-1">
			<h1 class="title-page">
				{{title}}
			</h1>
		</div>
	</div>
</template>

<script>
export default {

  name: 'HeaderPage',
  props: {
    title: {
      type: String,
    }
  },
  data () {
    return {

    }
  }
}
</script>

<style lang="css" scoped>
	.headerPage {
		padding: 35px 0;
		background: var(--tm-color);
		position: relative;
		z-index: 1;
		color: #fff;
	}
	.title-page {
		font-size: 30px;
		text-align: center;
		font-weight: 700;
		text-transform: uppercase;
		margin: 0;
	}
	.headerPage::before {
		content: '';
		display: block;
		position: absolute;
		inset: 0;
		background: linear-gradient(to top, var(--tm-color), #0000);
		mix-blend-mode: multiply;
		filter: hue-rotate(40deg);
	}
	@media(max-width: 767px){
		.title-page{
			font-size: 22px;
		}
	}
</style>