<template>
	<section id="Banner" class="sectionTemplate py-0">
		<div class="slider " ref="slider">
			<div v-if="banners.length > 0" class="wrapperSlick"  >
					<div class="banner-container" v-for="(item,index) in banners" :key="item.id" :class="{ 'active': index===posBanner }">
						<picture>
							<source media="(min-width:992px)" :srcset="(item.imagen)?item.imagen:'https://res.cloudinary.com/dppmjy7uj/image/upload/v1676564836/banners-template/laptop.jpg'">
							<source media="(min-width:700px)" :srcset="(item.imagen)?item.imagen:'https://res.cloudinary.com/dppmjy7uj/image/upload/v1676565646/banners-template/tablet.jpg'">
							<img class="img-banner" :srcset="(item.imagen)?item.imagen:'https://res.cloudinary.com/dppmjy7uj/image/upload/v1676565923/banners-template/movil.jpg'" alt="Banner">
						</picture>
						<div class="overlay"></div>
						<div class="content-banner">
							<h2 class="title-banner">{{item.titulo}}</h2>
							<div class="text-banner" v-html="item.descripcion">
							</div>
							<div class="link-banner">
								<a class="btn" :href="item.enlace">Ver más</a>
							</div>
						</div>
					</div>
					<a class="carousel-control-prev button-carrousel" role="button" data-slide="prev" @click="prevIMage()">
						<span class="carousel-control-prev-icon" aria-hidden="true"></span>
						<span class="sr-only">Previous</span>
					</a>
					<a class="carousel-control-next button-carrousel" role="button" data-slide="next" @click="nexImage()">
						<span class="carousel-control-next-icon" aria-hidden="true"></span>
						<span class="sr-only">Next</span>
					</a>
			</div>
			<div v-else>
				<div class="wrapperSlick">
					<div class="banner-container">
						<picture class="image-banner">
							<source media="(min-width:992px)" srcset="https://res.cloudinary.com/dppmjy7uj/image/upload/v1676564836/banners-template/laptop.jpg">
							<source media="(min-width:700px)" srcset="https://res.cloudinary.com/dppmjy7uj/image/upload/v1676565646/banners-template/tablet.jpg">
							<img class="img-banner" srcset="https://res.cloudinary.com/dppmjy7uj/image/upload/v1676565923/banners-template/movil.jpg" alt="Banner">
						</picture>
						<!-- <div class="overlay"></div>
						<div class="content-banner">
							<h2 class="title-banner"></h2>
							<div class="text-banner" >
							</div>
							<div class="link-banner">
								<a class="btn" > </a>
							</div>
						</div> -->
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import { imgStorageURL } from "@/core/helpers"
	export default {

		name: 'Banner',
		data(){
			return {
				imgStorageURL,
				posBanner:0,
			}
		},
		mounted(){
			this.$store.dispatch('AppBanner/getBanners')
			.then(() => {
				this.initSlider();
			});
		},
		computed:{
			banners(){
				return this.$store.state.AppBanner.banners;
			}
		},
		methods:{
			// initSlider(){
			// 	new Carousel(this.$refs['slider']);
			// },

			autoImage(){
				setTimeout(()=> {
					if(this.posBanner<this.banners.length-1){
					this.posBanner++
				}else{
					this.posBanner=0
				}
				}
				,2000)
			},
			nexImage(){
				if(this.posBanner<this.banners.length-1){
					this.posBanner++
				}else{
					this.posBanner=0
				}
			},
			prevIMage(){
				if(this.posBanner>0){
					this.posBanner--
				}else{
					this.posBanner=this.banners.length-1
				}
			}
		}
	}

</script>

<style lang="css" scoped>
	#Banner .slider {
		position: relative;
		display: flex;
		margin:0;
		--carousel-button-color: #170724;
		--carousel-button-bg: #fff;
		--carousel-button-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%),
		0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);

		--carousel-button-svg-width: 20px;
		--carousel-button-svg-height: 20px;
		--carousel-button-svg-stroke-width: 2.5;
		overflow: hidden;
	}
	.carousel__track{
		transform: none !important;
	}
	.wrapperSlick {
		display: block;
		width: 100%;
		padding: 0;
		flex-shrink: 0;
	}
	.banner-container {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100vw;
		display: none;
	}
	.banner-container .img-banner {
		inset: 0;
		display: block;
		width: 100%;
		height: 110%;
		object-fit:fill;
	}
	.button-carrousel{
		width: 5% !important;
	}

	.banner-container .content-banner {
		position: absolute;
		margin-left: 70px;
		z-index: 1;
		color: #fff;
		font-size: 20px;
		width: 50%;
		max-width: 800px;
		display: flex;
		flex-direction: column;
		bottom: 4rem;
	}

	.banner-container .overlay {
		position: absolute;
		inset: 0;
		background: linear-gradient(45deg, #000, #0000);
		mix-blend-mode: multiply;
	}

	.banner-container .title-banner {
		font-size: 3rem;
		line-height: 1.2;
		margin-bottom: 1rem;
		width: 100%;
	}

	.banner-container .text-banner {
		font-size: 1.1rem;
		line-height: 1.8;
	}

	.banner-container .link-banner .btn {
		font-size: 1.1rem;
		font-weight: 600;
		text-transform: uppercase;
	}

	.banner-container .link-banner {
		margin-top: 2rem;
	}

	.banner-container .text-banner > :first-child {
		margin-top: 0;
		padding-top: 0;
	}

	.banner-container .text-banner > :last-child {
		margin-bottom: 0;
		padding-bottom: 0;
	}
	.active{
		display: block;
	}

	.banner-container .title-banner::after {
		content: '';
		display: block;
		border-bottom: 4px solid var(--tm-color);
		width: 2em;
		margin-top: 6px;
	}
	@media(max-width: 990px){
		.banner-container .content-banner {
			max-width: none;
			width: 100%;
			height: auto;
			font-size: 16px;
		}
		.banner-container .title-banner {
			font-size: 2.2em;
		}
		.banner-container .text-banner {
			font-size: 1em;
			line-height: 1.6;
		}
		.banner-container .link-banner{
			margin-top: 1rem;
		}
		.banner-container {
			justify-content: flex-end;
		}
	}
	@media(max-width: 767px){
		.banner-container .overlay{
			background: linear-gradient( to top,#000, #0000);
		}
		.banner-container .content-banner {
			display: none;
		}
		.banner-container .img-banner{
			min-height: 160px ;
		}
		.banner-container .link-banner .btn {
			font-size: 0.9em;
		}
	}
	#Banner .slider .carousel__dots {
		top: initial;
		bottom: 15px;
		z-index: 1;
	}
	#Banner .slider .carousel__dots .carousel__dot::after{
		display:none;
	}
	#Banner .slider .carousel__dots .carousel__dot{
		background:#ffffff;
		height: 6px;
		width:15px;
		margin:4px;
		border-radius:2px;
		box-shadow: 2px 2px 5px #00000026;
		transition:all ease 0.4s;
	}

	#Banner .slider .carousel__dots .carousel__dot.is-selected {
		width:22px;
		background:var(--tm-color)
	}
</style>