<template>
	<div class="InmuebleItem d-flex flex-column flex-grow-1">
		<router-link 
		:to="{
			name: 'inmuebles-detalle',
			params: {slug: item.slug}
		}" 
		class="InmuebleItemWrapper d-flex flex-column flex-grow-1"
		>
		<figure class="thumbnailInmuebleItem" :class="{'default': !(item.inmueble_imagenes.length)}">
			<img :src="item.inmueble_imagenes.length > 0 ? imgStorageURL(item.inmueble_imagenes[0].url) : configuracion.logo" alt="Thumbnail">
		</figure>
		<div class="badgesInmuebleItem">
			<span class="tipo">{{item.tipo_inmueble.tipo}}</span>
			<span class="tipoNegocio">{{item.tipo_negocio.tipo}}</span>
		</div>
		<div class="datosInmuebleItem">
			<h3 class="tituloInmuebleItem">{{item.titulo_inmueble | lowerCase}}</h3>
			<div class="valorInmuebleItem">
				<div v-if="item.selling_price" class="precio d-flex">
					<span class="label">Venta:</span>
					<span class="valor">{{item.selling_price | precio}}</span>
				</div>
				<div v-if="item.rental_price" class="precio d-flex">
					<span class="label">Alquiler:</span>
					<span class="valor">{{item.rental_price | precio}}</span>
				</div>
			</div>
		</div>
		<div class="datosInmuebleItem mt-auto pt-0">
			<div class="attrInmuebleItem datosInmueble">
				<span class="habitaciones">
					<span class="labelLote">Hab.</span>
					<b>{{item.habitaciones}}</b>
				</span>
				<span class="banos">
					<span class="labelLote">Baños</span>
					<b>{{item.banos}}</b>
				</span>
				<span v-if="item.cantidad_parqueadero" class="parqueaderos">
					<span class="labelLote">Parq.</span>
					<b>{{item.cantidad_parqueadero}}</b>
				</span>
				<span class="pisos">
					<span class="labelLote">Pisos</span>
					<b>{{item.pisos}}</b>
				</span>
			</div>
			<div class="attrInmuebleItem areaInmueble">
				<span class="area">
					<div class="info">
						<span class="labelLote">Area lote m<sup>2</sup></span>
						<b>{{item.area_lote}}</b>
					</div>
				</span>
				<span class="ciudad">
					<span class="labelLote">Ciudad</span>
					<b>{{item.ciudad_id.name}}, {{item.estado_id.name}}</b>
				</span>
			</div>
		</div>
	</router-link>
</div>
</template>

<script>
	import { imgStorageURL } from "@/core/helpers";
	import mixinConfiguracion from '@/libs/mixins/mixinConfiguracion'
	export default {
		name: 'InmuebleItem',
		mixins: [mixinConfiguracion],
		props: {
			item: {
				type: Object,
				required: true
			}
		},
		data () {
			return {imgStorageURL};
		},
	}
</script>

<style lang="css" scoped>
	.InmuebleItemWrapper {
		display: flex;
		flex-direction: column;
		overflow: hidden;
		border-radius: 6px;
		border: 1px solid #ebe9f1;
		position: relative;
		background: #fff;
		color: inherit;
		text-decoration: none;
	}
	.InmuebleItemWrapper .thumbnailInmuebleItem {
		position: relative;
		padding-top: 70%;
	}
	.InmuebleItemWrapper .thumbnailInmuebleItem img {
		display: block;
		width: 100%;
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
		position: absolute;
		top: 0;
	}
	.InmuebleItemWrapper .thumbnailInmuebleItem.default img {
		object-fit: contain;
		padding: 15%;
	}

	.InmuebleItemWrapper .badgesInmuebleItem {
		position: absolute;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		line-height: 1;
		color: #fff;
		top: 9px;
	}

	.InmuebleItemWrapper .badgesInmuebleItem span {
		padding: 0.2em 0.8em;
		border-radius: 0 4px 4px 0;
		font-size: 14px;
		font-weight: 600;
	}

	.InmuebleItemWrapper .badgesInmuebleItem span+span {
		margin-top: 8px;
	}

	.InmuebleItemWrapper .badgesInmuebleItem .tipo {
		background: #e58b00;
	}

	.InmuebleItemWrapper .badgesInmuebleItem .tipoNegocio {
		background: #609d00;
	}
	.InmuebleItemWrapper .datosInmuebleItem {
		padding: 10px 20px 20px;
		display: flex;
		flex-direction: column;
	}

	.InmuebleItemWrapper .tituloInmuebleItem {
		font-size: 18px;
		margin-bottom: 20px;
		line-height: 1.2;
		font-weight: 400;
		text-transform: capitalize;
	}
	.InmuebleItemWrapper .valorInmuebleItem .precio+.precio {
		margin-top: 10px;
	}
	.InmuebleItemWrapper .valorInmuebleItem .precio .label{
		font-size: 14px;
		line-height: 1;
	}

	.InmuebleItemWrapper .valorInmuebleItem .precio .valor {
		font-weight: 700;
		font-size: 16px;
		line-height: 1;
		margin-left: auto;
	}

	.InmuebleItemWrapper .attrInmuebleItem {
		display: flex;
		font-size: 14px;
	}
	.InmuebleItemWrapper .attrInmuebleItem.datosInmueble{
		margin:0 -10px;
	}
	.InmuebleItemWrapper .attrInmuebleItem.areaInmueble {
		margin-top: 10px;
		padding-top: 10px;
		border-top: 1px solid #ebe9f1;
	}
	.InmuebleItemWrapper .attrInmuebleItem > span {
		display: flex;
		flex-direction: column;
	}
	.InmuebleItemWrapper .attrInmuebleItem.datosInmueble > span {
		width: 1em;
		flex-grow:1;
		padding: 0 10px;
	}
	.InmuebleItemWrapper .attrInmuebleItem.areaInmueble > span+span {
		margin-left: 10px;
	}

	.InmuebleItemWrapper .attrInmuebleItem > span .info {
		display: flex;
		flex-direction: column;
	}
	.InmuebleItemWrapper .attrInmuebleItem.datosInmueble > span:first-of-type{
		text-align: left;
	}
	.InmuebleItemWrapper .attrInmuebleItem.areaInmueble > span:last-of-type{
		text-align: right;
	}

	.InmuebleItemWrapper .attrInmuebleItem.areaInmueble > span:first-of-type {
		margin-right: auto;
	}
	
	.InmuebleItemWrapper .attrInmuebleItem .labelLote {
		font-size: 0.9em;
		opacity: 0.8;
	}
	.InmuebleItemWrapper .attrInmuebleItem > span b {
		font-weight: 600;
	}

</style>