<template>
  <article class="BlogItem d-flex flex-column flex-grow-1">
    <div class="wrapperBlog d-flex flex-column flex-grow-1">
      <router-link
        :to="{
          name: 'blog-detalle',
          params: { slug: item.slug },
        }"
        class="linkBlog d-flex flex-column flex-grow-1"
      >
        <div class="imageBlog">
          <picture class="thumbnailBlog">
            <template v-if="item.image">
              <source
                v-if="index === 0"
                media="(min-width:768px)"
                :srcset="item.image"
              />
              <source v-else media="(min-width:768px)" :srcset="item.image" />
              <img :src="item.image" :alt="item.titulo" />
            </template>
            <template v-else>
              <source media="(min-width:768px)" :srcset="configuracion.logo" />
              <img :src="configuracion.logo" :alt="item.titulo" />
            </template>
          </picture>
        </div>
        <div class="dateBlog">
          <span class="mounth">{{
            formtDate(item.created_at).mes.slice(0, 3)
          }}</span>
          <span class="day">{{ formtDate(item.created_at).dia }}</span>
        </div>
        <div class="contentBlog d-flex flex-column flex-grow-1">
          <h3 class="titleBlog">{{ item.titulo }}</h3>
          <div class="textBlog">
            {{ item.contenido | clearTag }}
          </div>
          <span class="viewMore">Leer mas →</span>
        </div>
      </router-link>
    </div>
  </article>
</template>

<script>
import { imgStorageURL, formtDate } from "@/core/helpers";
import mixinConfiguracion from "@/libs/mixins/mixinConfiguracion";
export default {
  name: "BlogItem",
  mixins: [mixinConfiguracion],
  props: {
    item: {
      type: Object,
    },
    index: Number,
  },
  data() {
    return { imgStorageURL, formtDate };
  },
};
</script>

<style lang="css">
.BlogItem {
  overflow: hidden;
  background: #fff;
  border-radius: 4px;
  box-shadow: 3px 3px 10px -1px #00000026;
}

.BlogItem .linkBlog {
  display: flex;
  flex-direction: column;
  color: inherit;
  text-decoration: none;
  position: relative;
}
.BlogItem .imageBlog {
  position: relative;
  padding-top: 60%;
}

@media (min-width: 768px) {
  .firstBlog {
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  .firstBlog:not(.without-image) .contentBlog {
    position: absolute;
    --bg-black: #0000008c;
    bottom: 0;
    background: var(--bg-black);
    color: #fff;
    width: 100%;
  }
  .firstBlog .imageBlog {
    position: relative;
    padding-top: 45%;
  }
  .firstBlog:not(.without-image) .contentBlog::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100px;
    background: linear-gradient(transparent, var(--bg-black));
    bottom: 100%;
    left: 0;
  }
}
.BlogItem .thumbnailBlog,
.thumbnailBlog img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.BlogItem .thumbnailBlog.default img {
  padding: 15%;
  object-fit: contain;
}

.BlogItem .dateBlog {
  position: absolute;
  top: 0;
  right: 20px;
  display: flex;
  flex-direction: column;
  color: #fff;
  background: var(--tm-color);
  filter: hue-rotate(30deg);
  line-height: 1;
  text-align: center;
  text-transform: uppercase;
  font-weight: 700;
  padding: 6px;
  border-radius: 0 0 3px 3px;
}

.BlogItem .contentBlog {
  padding: 20px 35px;
  display: flex;
  flex-direction: column;
}

.BlogItem .dateBlog .day {
  font-size: 1.5em;
}

.BlogItem .titleBlog {
  font-size: 22px;
  line-height: 1.2;
  margin-bottom: 12px;
  max-height: 1.2em;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}

.BlogItem .textBlog {
  line-height: 1.6;
  max-height: 3.2em;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  display: -webkit-box;
}

.BlogItem .viewMore {
  display: block;
  padding-top: 20px;
  margin-top: auto;
  text-decoration: underline;
  opacity: 0.85;
}
</style>