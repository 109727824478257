<template>
	<div class="row">
		<div class="col-12">
			<div class="row">
				<template v-if="data.data.length > 0">
					<div 
						v-for="(item,i) in data.data" 
						:key="i" 
						:class="{
							'firstBlog':i == 0 && blog,
							'without-image': !item.image && blog,
							'col-lg-4': inmueble
						}" 
						class="col-12 col-md-6 p-3 d-flex flex-column" 
					>
						<component :is="
							blog ? 
								'BlogItem' :
							inmueble ?
								'InmuebleItem' :
								''
							"
						:item="item" :index="i" />
					</div>
				</template>
				<template v-else>
					<b-col class="p-3">
						<b-alert show><h5 class="text-info">No hay {{blog ? 'Blogs' : 'Inmuebles'}} registrados</h5></b-alert>
					</b-col>
				</template>
			</div>
			<div class="row justify-content-center" v-if="pages > 1">
				<!-- <div class="col-12">
					<ul class="pagination mt-3 mb-0 justify-content-center">
						<li class="page-item arrow-p left" :class="{'d-none': (pageOn == 1)}">
							<a @click.prevent="prevPage" href="#" class="page-link">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M224 480c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25l192-192c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25L77.25 256l169.4 169.4c12.5 12.5 12.5 32.75 0 45.25C240.4 476.9 232.2 480 224 480z"/></svg>
							</a>
						</li>
						<li class="page-item" v-for="item in pages" :key="item" :class="{'active': pageOn == item}">
							<a class="page-link" @click.prevent="setPage(item)">{{item}}</a>
						</li>
						<li class="page-item arrow-p right" :class="{'d-none': (pageOn == pages)}">
							<a @click.prevent="nextPage" href="#" class="page-link">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>
							</a>
						</li>
					</ul>
				</div> -->
				<!-- PAGINADO -->
				<div class="col-auto">
					<b-pagination
						v-model="pageOn"
						:total-rows="data.total"
						:per-page="data.per_page"
						first-number
						last-number
					></b-pagination>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import BlogItem from "@/components/view/blog/BlogItem"
	import InmuebleItem from "@/components/view/inmueble/InmuebleItem"
	export default {

		name: 'Pagination',
		props:{
			blog:{
				type: Boolean
			},
			inmueble:{
				type: Boolean
			},
			data: Object,
		},
		components:{
			BlogItem,
			InmuebleItem
		},
		data () {
			return {
				pageOn: 1,
				openFiltro: false
			}
		},
		watch: {
			pageOn(a){
				if(this.pages > 1){
					if(this.inmueble)
						this.getData({page: a}, {
							overlay: 'AppInmuebles/SET_OVERLAY',
							dispatch: 'AppInmuebles/getInmuebles' 
						});
					else
						this.getData({page: a}, {
							overlay: 'AppBlog/SET_OVERLAY',
							dispatch: 'AppBlog/getBlogs' 
						});
				}
			},
			pages(a){
				if(a == 1)
					this.pageOn = a;
			}
		},
		computed: {
			pages(){
				return Math.ceil(this.data.total / this.data.per_page);
			},
		},
		methods: {
			getData(params, {overlay, dispatch}){
				let {$store} = this;
				$store.commit(overlay, true);
				$store.dispatch(dispatch, params)
				.then(() => {
					$store.commit(overlay, false);
				})
			},
			// Click paginador
			setPage(page){
				this.pageOn = page;
			},
			// Pagina siguiente
			nextPage(){
				if(this.pageOn < this.pages)
					this.pageOn++
			},
			// Pagina anterior
			prevPage(){
				if(this.pageOn > 1)
					this.pageOn--
			}
		}
	}
</script>