<template>
	<section id="contacto" class="sectionTemplate beforeFooter">
		<div class="container xs">
			<div class="row mx-n3">
				<div class="col-12 col-lg-6 p-3 order-0 order-lg-1">
					<h2 class="titleDefault align-items-center align-items-lg-start">Contáctanos</h2>
					<!-- <p style="margin-bottom:25px;">
						Lorem, ipsum dolor sit amet consectetur adipisicing elit. Doloribus assumenda ex maxime, voluptatum saepe aspernatur neque quod minima minus, consequuntur.
					</p> -->
					<Form v-bind="form" />
				</div>
				<div class="col-12 col-lg-6 p-3 d-flex flex-column">
					<Map class="flex-grow-1" v-bind="map" />
				</div>
			</div>
		</div>
	</section>
</template>
<script>
	import Form from '@/components/view/form/Form'
	import Map from '@/components/layout/Map'
	import mixinConfiguracion from '@/libs/mixins/mixinConfiguracion'
	export default {

		name: 'Contact',
		mixins: [mixinConfiguracion],
		components: {
			Form,
			Map
		},
		data(){
			return {
				form: {
					api: 'api/clientes-contactos',
					modal: {
						icon: 'success',
						title: 'Envío exitoso',
						text: 'Tu mensaje fue enviado exitosamente',
						showCloseButton: true,
						confirmButtonText: 'Cerrar',
					},
					inputs : {
						name: {
							value: null,
							component: 'b-form-input',
							attr: {
								label: {
									class: 'col-12',
									label: 'Nombre completo:',
									labelFor:'"name',
								},
								input: {
									type: 'text',	
									id: 'name',
									required: true,
								}
							}
						},
						email: {
							value: null,
							component: 'b-form-input',
							attr: {
								label: {
									class: "col-12 col-md-6",
									label: "Correo electrónico:",
									labelFor: "email",
								},
								input: {
									type: 'email',	
									id: 'email',
									required: true,
								}
							}
						},
						cell_phone: {
							value: null,
							component: 'b-form-input',
							attr: {
								label: {
									class: "col-12 col-md-6",
									label: "Celular:",
									labelFor: "cell_phone",
								},
								input: {
									type: 'text',	
									id: 'cell_phone',
									required: true,
								}
							}
						},
						description: {
							value: null,
							component: 'b-form-textarea',
							attr: {
								label: {
									class: "col-12",
									label: "Mensaje:",
									labelFor: "description",
								},
								input: {
									type: 'text',	
									id: 'description',
									required: true,
									rows: 4,
									maxRows: 8
								}
							}
						}
					},

				}

			}
		},
		computed: {
			map(){
				const {nombre, direccion, id_ciudad, id_estado, latitud, longitud, } = this.configuracion;
				return {
					nombre,
					direccion,
					ciudad: id_ciudad ? id_ciudad.name : null,
					estado: id_estado ? id_estado.name : null,
					lat: latitud,
					lng: longitud,
				}
			},
		}
	}
</script>