<template>
	<b-form @submit.prevent="onSubmit" class="contactForm d-flex flex-column">
		<div class="row">
			<b-form-group v-for="(item, prop) in inputs" :key="prop" v-bind="item.attr.label">
				<component v-model="item.value" :is="item.component" v-bind="item.attr.input" trim></component>
			</b-form-group>
		</div>
		<div class="row">
			<b-form-group class="col-12">
				<b-form-checkbox required name="TyC" id="TyC" value="true" unchecked-value="false">
					Acepto la política de tratamiento de datos personales
				</b-form-checkbox>
			</b-form-group>	
		</div>
		<b-button type="submit" :class="btn && btn.class ? btn.class : 'mr-auto' ">{{btn ? btn.text : 'Enviar'}}</b-button>
	</b-form>
</template>

<script>
	import mixinUserdata from '@/libs/mixins/mixinUserdata'
	export default {
		name: 'Form',
		mixins: [mixinUserdata],
		props:['btn', 'inputs', 'api', 'modal', 'id_property'],
		methods:{
			onSubmit(e){
				e.preventDefault;
				let {api, inputs, id_property} = this;
				let data = {id_user: this.userdata.id};
				if(id_property)
					Object.assign(data, {id_property});

				for(let prop in inputs)
					Object.assign(data, {[prop]: inputs[prop].value});

				this.$store.dispatch('AppForm/sendForm', {api, data})
				.then(() =>{
					for(let prop in inputs)
						Object.assign(inputs[prop],{value: null});

					this.$swal(this.modal);
				}).catch(() =>{
					
					this.$swal({
						icon: 'error',
						title: 'Oops...',
						text: 'Lo sentimos algo ocurrio intentalo mas tarde',
						showCloseButton: true,
						confirmButtonText: 'Cerrar',
					});
				});
			},
		}
	}
</script>
<style lang="css">
	[for="TyC"] {
		font-size: 13px;
	}
	.widget.newsletter {
		background: var(--tm-cop);
		border-radius: 6px;
		padding: 25px;
		color: #fff;
	}

	.widget.newsletter input {
		background: transparent;
	}

	#App .widget.newsletter .btn[type="submit"] {
		background: #fff;
		color: var(--tm-color);
		font-weight: 500;
		border: none;
	}
</style>