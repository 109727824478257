<template>
	<section id="MainFooter">
		<footer class="footer">
			<div class="container-fluid xxl">
				<div class="row justify-content-center">
					<div class="col-12 col-md-6 col-lg itemFooter">
						<figure class="logo-footer">
							<img :src="configuracion.logo" class="img-fluid" alt="">
						</figure>
						<div class="socialMedia">
							<a class="linkSocialMedia" target="_blank" :href="configuracion.facebook" v-if="configuracion.facebook != null"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"/></svg></a>
							<a class="linkSocialMedia" target="_blank" :href="configuracion.instagram" v-if="configuracion.instagram != null"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M224,202.66A53.34,53.34,0,1,0,277.36,256,53.38,53.38,0,0,0,224,202.66Zm124.71-41a54,54,0,0,0-30.41-30.41c-21-8.29-71-6.43-94.3-6.43s-73.25-1.93-94.31,6.43a54,54,0,0,0-30.41,30.41c-8.28,21-6.43,71.05-6.43,94.33S91,329.26,99.32,350.33a54,54,0,0,0,30.41,30.41c21,8.29,71,6.43,94.31,6.43s73.24,1.93,94.3-6.43a54,54,0,0,0,30.41-30.41c8.35-21,6.43-71.05,6.43-94.33S357.1,182.74,348.75,161.67ZM224,338a82,82,0,1,1,82-82A81.9,81.9,0,0,1,224,338Zm85.38-148.3a19.14,19.14,0,1,1,19.13-19.14A19.1,19.1,0,0,1,309.42,189.74ZM400,32H48A48,48,0,0,0,0,80V432a48,48,0,0,0,48,48H400a48,48,0,0,0,48-48V80A48,48,0,0,0,400,32ZM382.88,322c-1.29,25.63-7.14,48.34-25.85,67s-41.4,24.63-67,25.85c-26.41,1.49-105.59,1.49-132,0-25.63-1.29-48.26-7.15-67-25.85s-24.63-41.42-25.85-67c-1.49-26.42-1.49-105.61,0-132,1.29-25.63,7.07-48.34,25.85-67s41.47-24.56,67-25.78c26.41-1.49,105.59-1.49,132,0,25.63,1.29,48.33,7.15,67,25.85s24.63,41.42,25.85,67.05C384.37,216.44,384.37,295.56,382.88,322Z"/></svg></a>
							<a class="linkSocialMedia" target="_blank" :href="configuracion.linkedin" v-if="configuracion.linkedin != null"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg></a>
						</div>
					</div>
					<div class="col-12 col-md-6 col-lg itemFooter">
						<h4 class="titleFooter">Sobre nosotros</h4>
						<ul class="menuList">
							<li class="itemMenuList"><router-link :to="{name: 'sobre-nosotros'}" class="linkMenuList">Sobre nosotros</router-link></li>
							<!-- <li class="itemMenuList"><a class="linkMenuList" href="#">Política de tratamiento de datos personales</a></li> -->
							<!-- <li class="itemMenuList"><a class="linkMenuList" href="#">Terminos y condiciones</a></li> -->
						</ul>
					</div>
					<div class="col-12 col-md-6 col-lg itemFooter">
						<h4 class="titleFooter">Inmuebles</h4>
						<ul class="menuList">
							<li class="itemMenuList" v-for="item in inmueblesList" :key="item.id" style="text-transform: capitalize;">
								<router-link 
								:to="{
									name:'inmuebles-detalle',
									params: {slug: item.slug}
								}" 
								class="linkMenuList"
								>
								{{item.titulo_inmueble | lowerCase}}
							</router-link>
						</li>
					</ul>
				</div>
				<div class="col-12 col-md-6 col-lg itemFooter">
					<h4 class="titleFooter">Contacto</h4>
					<ul class="menuList">
						<li class="itemMenuList addIcon" v-if="configuracion.direccion != null">
							<div class="icon">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M168.3 499.2C116.1 435 0 279.4 0 192C0 85.96 85.96 0 192 0C298 0 384 85.96 384 192C384 279.4 267 435 215.7 499.2C203.4 514.5 180.6 514.5 168.3 499.2H168.3zM192 256C227.3 256 256 227.3 256 192C256 156.7 227.3 128 192 128C156.7 128 128 156.7 128 192C128 227.3 156.7 256 192 256z"/></svg>
							</div>
							<a class="linkMenuList" href="#">
								<span class="address">{{configuracion.direccion}}</span>
								<span class="cityState">{{configuracion.ciudad}}, {{configuracion.estado}}</span>
							</a>
						</li>
						<li class="itemMenuList addIcon" v-if="configuracion.celular != null">
							<div class="icon">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M320 0H64C37.5 0 16 21.5 16 48v416C16 490.5 37.5 512 64 512h256c26.5 0 48-21.5 48-48v-416C368 21.5 346.5 0 320 0zM240 447.1C240 456.8 232.8 464 224 464H159.1C151.2 464 144 456.8 144 448S151.2 432 160 432h64C232.8 432 240 439.2 240 447.1zM304 384h-224V64h224V384z"/></svg>
							</div>
							<a class="linkMenuList" :href="`tel:${configuracion.paisCodigo+configuracion.celular}`">({{configuracion.paisCodigo}}) {{configuracion.celular}}</a>
						</li>
						<li class="itemMenuList addIcon" v-if="configuracion.email != null">
							<div class="icon">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M464 64C490.5 64 512 85.49 512 112C512 127.1 504.9 141.3 492.8 150.4L275.2 313.6C263.8 322.1 248.2 322.1 236.8 313.6L19.2 150.4C7.113 141.3 0 127.1 0 112C0 85.49 21.49 64 48 64H464zM217.6 339.2C240.4 356.3 271.6 356.3 294.4 339.2L512 176V384C512 419.3 483.3 448 448 448H64C28.65 448 0 419.3 0 384V176L217.6 339.2z"/></svg>
							</div>
							<a class="linkMenuList" :href="`mailto:${configuracion.email}`">{{configuracion.email}}</a>
						</li>
						<li class="itemMenuList addIcon" v-if="configuracion.paginaWeb != null">
							<div class="icon">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M352 256C352 278.2 350.8 299.6 348.7 320H163.3C161.2 299.6 159.1 278.2 159.1 256C159.1 233.8 161.2 212.4 163.3 192H348.7C350.8 212.4 352 233.8 352 256zM503.9 192C509.2 212.5 512 233.9 512 256C512 278.1 509.2 299.5 503.9 320H380.8C382.9 299.4 384 277.1 384 256C384 234 382.9 212.6 380.8 192H503.9zM493.4 160H376.7C366.7 96.14 346.9 42.62 321.4 8.442C399.8 29.09 463.4 85.94 493.4 160zM344.3 160H167.7C173.8 123.6 183.2 91.38 194.7 65.35C205.2 41.74 216.9 24.61 228.2 13.81C239.4 3.178 248.7 0 256 0C263.3 0 272.6 3.178 283.8 13.81C295.1 24.61 306.8 41.74 317.3 65.35C328.8 91.38 338.2 123.6 344.3 160H344.3zM18.61 160C48.59 85.94 112.2 29.09 190.6 8.442C165.1 42.62 145.3 96.14 135.3 160H18.61zM131.2 192C129.1 212.6 127.1 234 127.1 256C127.1 277.1 129.1 299.4 131.2 320H8.065C2.8 299.5 0 278.1 0 256C0 233.9 2.8 212.5 8.065 192H131.2zM194.7 446.6C183.2 420.6 173.8 388.4 167.7 352H344.3C338.2 388.4 328.8 420.6 317.3 446.6C306.8 470.3 295.1 487.4 283.8 498.2C272.6 508.8 263.3 512 255.1 512C248.7 512 239.4 508.8 228.2 498.2C216.9 487.4 205.2 470.3 194.7 446.6H194.7zM190.6 503.6C112.2 482.9 48.59 426.1 18.61 352H135.3C145.3 415.9 165.1 469.4 190.6 503.6V503.6zM321.4 503.6C346.9 469.4 366.7 415.9 376.7 352H493.4C463.4 426.1 399.8 482.9 321.4 503.6V503.6z"/></svg>
							</div>
							<a class="linkMenuList" target="_blank" :href="configuracion.paginaWeb">{{configuracion.paginaWeb}}</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="row">
				<div class="col"><h5 class="copyRigth">Red CRM &copy; {{año}} | <a href="https://crmred.co" target="_blank">Powered by RED</a></h5></div>
			</div>
		</div>
	</footer>
</section>
</template>

<script>
	import mixinConfiguracion from '@/libs/mixins/mixinConfiguracion'
	import mixinInmuebles from '@/libs/mixins/mixinInmuebles'
	export default {

		name: 'MainFooter',
		mixins: [mixinConfiguracion, mixinInmuebles],
		data () {
			return {
				'año': new Date().getFullYear()
			}
		},
		mounted(){
			this.$store.dispatch('AppInmuebles/getListInmuebles');
		}
	}
</script>

<style lang="css" scoped>
	#MainFooter {
		background: linear-gradient(#f0f0f0, #fff);
		padding-top: 40px;
	}
	@media(min-width:768px){
		#MainFooter {
			position: sticky;
			bottom: 0;
			z-index: 1;
		}
	}
	#MainFooter .logo-footer img {
		display: block;
		height: auto;
		max-width: 140px;
		margin: 0 auto;
	}
	#MainFooter .itemFooter {
		padding: 15px 25px;
	}
	@media(min-width: 992px){
		#MainFooter .itemFooter+.itemFooter {
			border-left: 1px solid #e2e2e2;
		}
	}

	#MainFooter .titleFooter {
		font-size: 18px;
		opacity: 0.9;
		font-weight: 500;
		margin-bottom: 25px;
	}

	#MainFooter .menuList {
		display: flex;
		flex-direction: column;
		list-style: none;
		padding: 0 8px;
		margin: 0;
		opacity: 0.6;
		font-size: 14px;
		line-height: 1.2;
	}

	#MainFooter .linkMenuList {
		color: inherit;
	}

	#MainFooter .itemMenuList+.itemMenuList {
		margin-top: 15px;
	}
	#MainFooter .socialMedia {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-top: 20px;
		font-size: 30px;
	}

	#MainFooter .linkSocialMedia {
		margin: 4px;
		display: block;
		color: var(--tm-color);
		opacity: 0.8;
	}

	#MainFooter .linkSocialMedia svg {
		display: block;
	}
	#MainFooter .copyRigth {
		line-height: 1.2;
		font-size: 14px;
		opacity: 0.6;
		text-align: center;
		font-weight: 500;
		padding: 20px 0;
		margin: 40px 0 0;
	}
	#MainFooter .itemMenuList.addIcon {
		display: flex;
	}

	#MainFooter .itemMenuList.addIcon .icon {
		font-size: 16px;
		width: 1.25em;
		text-align: center;
		flex-shrink: 0;
		margin-right: 8px;
		opacity: 0.5;
	}

	#MainFooter .itemMenuList .cityState {
		display: block;
		font-size: 0.85em;
	}
</style>