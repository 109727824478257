<template>
	<section id="MainHeader">
		<header class="header d-flex align-items-center">
			<div class="container-fluid xxl">
				<div class="row align-items-center">
					<div class="col-auto">
						<figure class="logo-header mb-0">
							<router-link class="menu-link" :to="{ name: links[0].name }">
								<img :src="configuracion.logo" class="img-fluid" alt="">
							</router-link>
						</figure>
					</div>
					<div class="col-auto ml-auto">
						<nav class="nav-menu" :class="{'active':menu}">
							<ul class="menu">
								<li class="menu-item" v-for="(item, i) in links" :key="i">
									<router-link class="menu-link" :to="item.hash ? `${item.path}${item.hash}` : item.path ">{{item.text}}</router-link >
								</li>
							</ul>
						</nav>
						<button @click="menuMobile" class="btn-mobile d-flex align-items-center justify-content-center d-lg-none">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M0 96C0 78.33 14.33 64 32 64H416C433.7 64 448 78.33 448 96C448 113.7 433.7 128 416 128H32C14.33 128 0 113.7 0 96zM0 256C0 238.3 14.33 224 32 224H416C433.7 224 448 238.3 448 256C448 273.7 433.7 288 416 288H32C14.33 288 0 273.7 0 256zM416 448H32C14.33 448 0 433.7 0 416C0 398.3 14.33 384 32 384H416C433.7 384 448 398.3 448 416C448 433.7 433.7 448 416 448z"/></svg>
						</button>
					</div>
				</div>
			</div>
		</header>
	</section>
</template>

<script>
	import mixinConfiguracion from '@/libs/mixins/mixinConfiguracion'
	export default {
		name: 'MainHeader',
		mixins: [mixinConfiguracion],
		data () {
			return {
				menu: false,
				links: this.$router.options.routes
			}
		},
		methods:{
			menuMobile(){
				this.menu = !this.menu;
			}
		}
	}
</script>

<style lang="css" scoped>
	/* .router-link-active{
	border-bottom: 2px solid var(--tm-color);
	border-radius: 5px;
	} */
	#MainHeader {
		position: sticky;
		top: 0;
		z-index: 99;
	}
	.header {
		padding: 10px 0;
		box-shadow: 0 3px 10px #00000026;
		background: #fff;
		height: 90px;

	}
	.logo-header img {
		width: auto;
		height: 70px;
	}
	.menu {
		margin: 0;
		padding: 0;
		display: flex;
		line-height: 1.4;
		font-size: 16px;
	}
	.menu .menu-item {
		list-style: none;
	}
	.menu li .menu-link {
		display: flex;
		padding: 10px 20px;
		color: #333;
		font-weight: 400;
	}
	@media(max-width: 991px){
		.btn-mobile {
			border: 1px solid rgb(123 123 123 / 60%);
			color: inherit;
			padding: 0;
			line-height: 1;
			width: 1.6em;
			height: 1.6em;
			font-size: 24px;
			border-radius: 5px;
			background: transparent;
			transition: all ease 0.4s;
		}

		.btn-mobile:hover {
			color: var(--tm-cop);
		}

		.nav-menu {
			position: absolute;
			right: 0;
			top: 100%;
			margin-top: 10px;
			margin-right: 20px;
			transition: all ease 0.4s;
			transform-origin: right top;
			transform: scale(0);
			pointer-events: none;
		}
		.nav-menu.active{
			transform: scale(1);
			pointer-events: auto;
		}

		.menu {
			flex-direction: column;
			width: 80vw;
			max-width: 280px;
			background: #fff;
			box-shadow: 3px 3px 10px #00000026;
			border-top: 4px solid var(--tm-cop);
			padding: 15px 25px;
		}

		.menu .menu-item+.menu-item {
			border-top: 1px solid #ebe9f1;
		}
	}
	@media(max-width: 767px){
		.logo-header img {
			max-height: 50px;
		}
		.header{
			height: 70px;
		}
	}
</style>