<template>
	<div id="AboutUs">
		<HeaderPage title="Sobre nosotros" />
		<div class="container-fluid md xxl section-page">
			<div class="row justify-content-center">
				<div class="col-7">
					<div class="row my-2 mx-n4">
						<div class="col-12 p-4">
							<figure v-if="configuracion.logo != 'null'" class="aboutUsLogo">
								<img class="img-fluid" :src="configuracion.logo" alt="">
							</figure>
							<div class="aboutUsDescripcion aboutUsContent" v-html="configuracion.descripcion"></div>
						</div>
						<div class="col p-4" v-if="configuracion.mision != 'null'">
							<h2 class="aboutUsSubtitle">Misión</h2>
							<div class="aboutUsMision aboutUsContent" v-html="configuracion.mision"></div>
						</div>
						<div class="col p-4" v-if="configuracion.vision != 'null'">
							<h2 class="aboutUsSubtitle">Visión</h2>
							<div class="aboutUsVision aboutUsContent" v-html="configuracion.vision"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import HeaderPage from '@/components/layout/HeaderPage'
	import mixinConfiguracion from '@/libs/mixins/mixinConfiguracion'
	export default {

		name: 'AboutUs',
		mixins: [mixinConfiguracion],
		components:{
			HeaderPage
		},
	}
</script>

<style lang="css" scoped>
#AboutUs .aboutUsLogo{
	max-width: 200px;
	margin: 0 auto 30px;
}
.aboutUsDescripcion {
  text-align: justify;
  text-align-last: center;
}

.aboutUsSubtitle {
  font-size: 24px;
  text-transform: uppercase;
  font-weight: 700;
  padding-bottom: 6px;
  margin-bottom: 10px;
  border-bottom: 2px solid var(--tm-color);
}
#AboutUs .aboutUsContent :last-child{
	margin-bottom: 0;
}
</style>