<template>
	<div class="blogContent">
		<picture class="imageBlogDetail" v-if="detail.image">
			<source media="(min-width:768px)" :srcset="detail.image">
			<img class="img-fluid" :src="detail.image" :alt="detail.titulo">
		</picture>
		<h2 class="titleBlogDetail">{{detail.titulo}}</h2>
		<div class="dateBlogDetail">
			{{`${formtDate(detail.created_at)['mes']} ${formtDate(detail.created_at)['dia']} de ${formtDate(detail.created_at)['año']}`}}
		</div>
		<div class="contentBlogDetail" v-html="detail.contenido"></div>
	</div>
</template>

<script>
	import { formtDate } from "@/core/helpers"
	import mixinBlogs from '@/libs/mixins/mixinBlogs'
	export default {

		name: 'BlogDetail',
		mixins: [mixinBlogs],

		data () {
			return {
				formtDate,
				blogDetail:{
					titulo: 'Blog'
				}
			}
		},
		computed:{
			detail(){
				let detail = this.blogs.grid.data.filter(item => item.slug == this.$route.params.slug );
				return detail[0];
			},
		},
	}
</script>

<style scoped>
.titleBlogDetail {
  font-size: 26px;
  margin-bottom: 0;
  margin-top: 20px;
}

.dateBlogDetail {
  opacity: 0.75;
  font-size: 14px;
  margin-bottom: 20px;
}

</style>